import "./App.css";
import 'antd/dist/antd.min.css';
import { Layout } from "antd";
import { Carousel } from "antd";
import RENEWLOGO from "./images/symbol-logo.png";
import petFlakesImg from './images/PLASTIC7.jpeg'
import { PageHeader, Descriptions,Badge } from "antd";

const { Header, Footer, Content } = Layout;
const renderContent = (column = 1) => (
  <Descriptions size="small" column={column}>
    <Descriptions.Item label="Phone">  <Badge status="processing" text="+91 75859 36369" /></Descriptions.Item>
    <Descriptions.Item label="Email">
    <a href="mailto:info@renewindustries.in">info@renewindustries.in</a>
    </Descriptions.Item>
    <Descriptions.Item label="Address">
     Adda Industrial Estate, Plot No C13, Near ESI Hospital Asansol - 713 305, West Bengal
    </Descriptions.Item>
  </Descriptions>
);

const ContacUsContent = ({ children }) => (
  <div className="content">
    <div className="main">{children}</div>
  </div>
);

const App = () => {
  const contentStyle = {
    maxHeight: "350px",
    width: "100%",
    color: "#fff",
    lineHeight: "200px",
    textAlign: "center",
    background: "#364d79",
  };

  const logoImgStyle = {
    height: "44px",
    display: "inline-block",
    marginTop: "0rem",
    marginBottom: "0.25rem",
  };

  return (
    <>
      <Layout className="layout">
        <Header>
          <div className="header-title">
            <img style={logoImgStyle} src={RENEWLOGO} alt="renew industries logo"/>
            <h2
              style={{
                color: "#fff",
                display: "inline-block",
                marginLeft: "1rem",
              }}
            >
              RENEW INDUSTRIES{" "}
            </h2>
          </div>
        </Header>

        <Content style={{ padding: "1rem" }}>
          <Carousel autoplay>
            <div>
              <img style={contentStyle} src={petFlakesImg} loading="lazy" alt="PET Flakes"/>
            </div>
          </Carousel>
          <div className="site-layout-content">
            <PageHeader
              className="site-page-header-responsive"
              title="Contact Us"
            >
              <ContacUsContent>{renderContent()}</ContacUsContent>
            </PageHeader>
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
        Copyright © 2021 All rights reserved.
        </Footer>
      </Layout>
    </>
  );
};

export default App;
